.signatureCanvas{
  border-bottom: 2px solid #000;
  background-color: #eee;
}

.cursor-pointer{
  cursor: pointer;
}

.MuiPaper-root input.MuiInput-input[aria-label=Search]{
  border: 1px solid var(--ct-input-border-color);
  border-radius: 0.25rem;
  padding: 0.45rem 0.9rem;
  font-weight: 400;
  color: var(--ct-input-color);
  background-color: var(--ct-input-bg);
  background-clip: padding-box;
  appearance: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.css-ghsjzk-MuiInputBase-root-MuiInput-root::before,.css-ghsjzk-MuiInputBase-root-MuiInput-root::after{
  border-bottom: none !important;
}

.float-right{
  float: right;
}

@media (max-width: 576px) {
  .text-xs-end{
    text-align: right !important;
  }
}

#signature{
  text-align: center;
}

#signature .signatureCanvas{
  margin: 0 auto;
  display: block;
}
