.loading-content{
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.3);
  align-items: center;
}

.loading-content .spinner-border{
  z-index: 9999;
  width: 70px !important;
  height: 70px !important;
  margin: 0 auto;
}
